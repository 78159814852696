/********* Adaptation class Bootstrap *********/
/********* FIN Adaptation class Bootstrap *********/
/***************************************************** PAGE LOGIN *****************************************************/
/********* Header Page login *********/
/********* FIN Header Page login *********/
/********* Corp Page login *********/
/********* FIN Corp Page login *********/
/********* Footer Page login *********/
/********* FIN Footer Page login *********/
/***************************************************** FIN PAGE LOGIN *****************************************************/
/***************************************************** PAGE ACCUEIL ADMIN *****************************************************/
/********* Header Page Accueil Admin *********/
/********* FIN Header Page Accueil Admin *********/
/********* Corp Page Accueil Admin *********/
/********* FIN Corp Page Accueil Admin *********/
/***************************************************** FIN PAGE ACCUEIL ADMIN *****************************************************/
/***************************************************** Admin *****************************************************/
/***************************************************** FIN Admin *****************************************************/
/*
Homogénéité micro netoyage
 */
@import 'variables';

* {
	margin: 0;
	padding: 0;
	border: 0;
}

a {
	color: black;
	text-decoration: none;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

.relative {
	position: relative;
}

.jumbotron {
	background-color: #3E5823;
	padding-top: 25px;
	padding-bottom: 25px;
	margin-bottom: 60px;

	h1 {
		color: #B8BE14;
	}

	h2 {
		color: #B8BE14;
	}
}

.panel-default {
	background-color: #F0F0F0;
}

.btn-default {
	background-color: #B8BE14;
	color: #fff;
	border: none;
	box-shadow: none;
}

.form-control {
	box-shadow: none;
}

select.form-control {
	width: auto;
}

#header-login {
	display: flex;
	justify-content: space-around;
}

header {
	div {
		display: flex;

		div {
			&:last-child {
				// flex-direction: column;
				// justify-content: space-around;
				font-size: 20px;
				color: #fff;
			}
		}
	}
}

#logo {
	img {
		margin-right: 30px;
		align-self: center;
	}
}

body {
	background-color: #F0F0F0;
}

#ntic-section-login {
	display: flex;
	justify-content: space-around;
	margin-bottom: 50px;
	margin-top: 50px;
	text-align: center;

	div {
		form {
			width: 260px;
			margin: 0 auto;
		}

		h2 {
			margin-bottom: 50px;
		}
	}
}

.ntic-connexion {
	background-color: #F2F1EF;
	height: 50px;
	border-radius: 0px;
	margin-bottom: 10px;
}

.input-ntic-connexion {
	margin-bottom: 35px;

	a {
		color: #000;
		text-decoration: underline;
		font-size: 12px;
		margin-left: 25px;
		margin-top: 1px;
	}
}

section {
	form {
		div {
			&:last-child {
				display: flex;
				justify-content: space-around;
			}
		}
	}
}

#ntic-input-connexion {
	width: 260px;
	height: 50px;
	border-radius: 0px;
	font-size: 18px;
	margin-top: 25px;
}

footer {
	display: flex;
	justify-content: space-around;
	border-top: 1px solid #B8BE14;

	img {
		height: 46px;
	}
}

#ntic-footer-contener {
	text-align: center;
	margin-top: 30px;

	p {
		margin: 0 auto;
		text-align: center;
	}

	img {
		margin: 0 auto;
		text-align: center;
	}
}

#ntic-footer-image {
	display: flex;
	justify-content: space-around;
}

.header-other {
	padding: 0;

	>a {
		height: fit-content;

		>div {
			>div {
				&:first-child {
					>img {
						width: 275px;
						padding: 5px 17px;
						margin-right: 15px;

						@media (max-width: $bp-tablet) {
							width: 150px;
						}
					}
				}
			}

			&:first-child {
				>div {
					&:last-child {
						font-size: 15px;
					}
				}
			}
		}
	}

	>div {
		&:last-child {
			>div {
				display: flex;
				flex-direction: row;
			}
		}
	}
}

#ntic-circle {
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 20px;
}

#ntic-deconnexion {
	>a {
		>img {
			width: 15px;
			cursor: pointer;
			margin-right: 10px;
		}
	}
}

#ntic-user {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 0 10px;
	font-size: 12px;

	>p {
		&:first-child {
			font-size: 15px;
			margin-bottom: 0;
		}

		&:last-child {
			font-size: 10px;
			margin-bottom: 0;
		}
	}
}

.ntic-flex-start {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.ntic-align-vertical {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.ntic-align-vertical-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.ntic-align-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.ntic-align-horizontal-center {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.ntic-flex-end {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.ntic-inline-block {
	display: flex;
}

.ntic-item-espace-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: $bp-tablet) {
		align-items: center;
	}
}

.ntic-item-espace-vertical {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#ntic-index-admin {
	margin: 0;
	margin-bottom: 50px;
}

#section-accueil-admin {
	margin-bottom: 20px;
	margin-top: 8px;
}

#ntic-titre-index-admin {
	>div {
		>p {
			>span {
				&:first-child {
					color: #B8BE14;
					font-size: 28px;
				}

				&:last-child {
					font-size: 15px;
				}
			}
		}
	}
}

.ntic-text-centre {
	text-align: center;
	margin-bottom: 0;
}

.ntic-gestion {
	background-color: #F0F0F0;
	height: 175px;
	margin: 0 20px;

	>div {

		&:last-child {
			>div {
				margin: 10px 0 0;
			}
		}
	}

	i {
		font-size: 3em;
		color: #B8BE14;
	}
}

div.ntic-gestion {
	>div.ntic-align-horizontal {
		>div.ntic-inline-block {
			>.ntic-conseil {
				margin: 18px 0 22px 0;
			}
		}
	}
}

#ntic-titre-index-admin {
	margin-bottom: 15px;
}

.ntic-titre-rebrique {
	margin-right: 10px;
	font-size: 16px;
}

#row2 {
	margin-top: 20px;
}

.body-type {
	background-color: #fff;

	header {
		margin-bottom: 0;
	}

	>div {
		>div {
			>#content {
				>h2 {
					>a.pull-left {
						&:hover {
							text-decoration: none;
						}

						&:focus {
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}

.ntic-titre-page {
	background-color: #D8D8D8;
	height: 73px;

	h1 {
		font-size: 25px;
	}
}

#menu {
	padding-left: 0;
	padding-right: 15px;

	.ntic-menu-container {
		.ntic-menu {
			>a {
				&:hover {
					background-color: #D7D7D8;
				}

				&:focus {
					background-color: #D7D7D8;
				}
			}
		}
	}
}

.img-fleche-grise {
	width: 18px;
	background-image: url(../images/fleche-grise.png);
	background-size: cover;
}

.img-fleche-grise-bas {
	width: 18px;
	background-image: url(../images/fleche-grise-bas.png);
	background-size: cover;
}

.ntic-menu-container {
	background-color: #F0F0F0;
}

.ntic-menu {
	border-bottom: 1px solid #D8D8D8;
	margin-top: 0;

	>a {
		>div {
			>span {
				color: black;
				font-weight: bold;
			}
		}

		color: black;
	}

	>ul {
		>li {
			>a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

li.ntic-menu {
	>a {
		>div {
			>i.fa {
				padding-right: 5px;
			}
		}
	}
}

.glyphicon-chevron-right {
	color: #B8BE14;
}

.glyphicon-chevron-down {
	color: #B8BE14;
}

.glyphicon-edit {
	color: #B8BE14;
}

.glyphicon-trash {
	color: black;
}

li {
	list-style: none;
}

.background-grey {
	background-color: #F0F0F0;
}

.background-dark-grey {
	background-color: #D7D7D8;
}

.ntic-sous-menu {
	padding: 10px 0 10px 45px;
	color: #000;
	text-decoration: none;

	&:hover {
		background-color: #D7D7D8;
	}
}

table.table-striped {
	>thead {
		>tr {
			>th {
				color: #B8BE14;
				border-top: 1px solid #ABABAB;
			}
		}
	}
}

td {
	border: 1px solid #ABABAB;
}

div#content {
	>table {
		>thead {
			>tr {
				>th {
					border: 1px solid #ABABAB;
				}
			}
		}
	}
}

.ntic-ajouter {
	border: 1px solid #B8BE14;
	border-radius: 5px;
	margin: 30px 0 35px 30px;

	>div {
		&:first-child {
			padding: 5px 10px 5px 5px;
		}

		&:last-child {
			font-size: 15px;
			color: #B8BE14;
			padding: 5px 10px 5px 5px;
		}
	}
}

#td-blanc {
	background-color: #fff;
	border: 0;
}

#content {
	.th-sans-border {
		border: 0;
	}

	margin-top: 35px;
}

.ntic-retour {
	border: 1px solid #000;
	border-radius: 5px;
	margin-top: 16px;

	>div {
		>div {
			>i {
				margin-top: 5px;
			}

			>p {
				margin-top: 2px;
				margin-bottom: 0;
			}

			padding: 5px 0;
		}

		cursor: pointer;
	}
}

#hel_ccpscbundle_prepareception_submit {
	margin-top: 18px;
}

.ntic-section-form {
	border: 1px solid #F0F0F0;
	padding: 20px 20px;
	margin-bottom: 25px;

	.pb-10 {
		padding-bottom: 10px;
	}

	&:first-child {
		background-color: #F0F0F0;
		margin-bottom: 25px;
		border: 1px solid #D8D8D8;

		label {
			color: #B8BE14;
		}

		input {
			color: #B8BE14;
			background-color: #fff;
		}

		select {
			color: #B8BE14;
			background-color: #fff;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	input {
		background-color: #F0F0F0;
		border-radius: 0;
		border: 1px solid #DFDFDF;
	}

	textarea {
		background-color: #F0F0F0;
		border-radius: 0;
		border: 1px solid #DFDFDF;
	}

	select {
		background-color: #F0F0F0;
		border-radius: 0;
		border: 1px solid #DFDFDF;
	}
}

.mesure-depaillage {
	padding-bottom: 7px;
	display: flex;

	.rmv_dynamic_collection {
		align-self: flex-end;
	}
}

.input-group-addon {
	border-radius: 0;
	border-color: #DFDFDF;
	min-width: 150px;
}

.input-group-btn {
	.btn.btn-danger {
		border: 1px solid #DFDFDF;
		border-radius: 0;
	}
}

#hel_ccpscbundle_infosechagedepaillage_Enregistrer {
	margin-top: 18px;
}

#hel_ccpscbundle_infosechagedepaillage_lot {
	display: flex;

	label {
		color: #B8BE14;
	}
}

#hel_ccpscbundle_infosechagedepaillage_mesureSechages_0 {
	display: flex;
}

#hel_ccpscbundle_reception_lot {
	display: flex;
}

#hel_ccpscbundle_reception_humiditeMoyenne {
	z-index: 0;
}

#lots {
	>div {
		>a {
			>div {
				border: 1px solid #D8D8D8;
				margin-bottom: 10px;
				padding: 5px 7px;

				&:hover {
					background-color: #D8D8D8;
					border-radius: 5px;
				}

				>div {
					>h4 {
						color: #B8BE14;
						font-weight: bold;
					}
				}
			}
		}
	}
}

span.content-autocomplete {
	display: block;
	position: relative;
}

.ui-autocomplete {
	overflow-y: scroll;
	height: 200px;
	z-index: 999;
}

.spinner {
	width: 30px;
	height: 30px;
	background-image: url(../images/rolling.gif);
	background-size: cover;
	display: none;
	position: absolute;
	bottom: 2px;
	right: 30px;
}

.ui-front {
	position: relative;
}

#button-print {
	padding: 5px 0;

	>button {
		padding: 2px 16px;
		border-radius: 5px;
		background-color: #B8BE14;
		color: white;
	}
}

.page-active {
	background-color: #D8D8D8;
}

.ntic-masque {
	display: none;
}

.ntic-affiche {
	display: block;
}

#hel_ccpscbundle_reception_save_stay {
	margin-top: 10px;
}

form {
	.input-group-addon {
		padding: 6px 4px;
	}
}

#hel_ccpscbundle_infoprepanettoyage_poidsTota {
	width: 150px;
}

#hel_ccpscbundle_infoprepanettoyage_poidsDemandel {
	width: 150px;
}

#hel_ccpscbundle_infoprepanettoyage_suffixeLot {
	width: 150px;
}

#hel_ccpscbundle_info_prepa_conditionnement_poidsTotal {
	width: 150px;
}

#hel_ccpscbundle_info_prepa_conditionnement_poidsDemande {
	width: 150px;
}

#hel_ccpscbundle_info_prepa_conditionnement_numLot {
	width: 150px;
}

a.sort {
	display: block;
	width: 100%;
}

.normal-margin {
	margin-bottom: 10px;
}

.flex-title {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	a {
		i {
			font-size: 24px;
			color: #B8BE14;
		}

		margin-bottom: 10px;
	}
}

.table-resize {
	.form-control {
		padding: 6px 8px;
		font-size: 11px;
		width: 80%;
	}

	.btn {
		font-size: 11px;
		padding: 6px 4px;
	}

	.form-inline {
		.form-control {
			width: 100%;
		}
	}

	.input-group {
		.form-control {
			width: 100%;
		}
	}

	.btn.btn-sm {
		padding: 5px 10px;
	}
}

.form-control.calcul {
	max-width: 300px;
}

ul.liste_autre_plante {
	li {
		padding-top: 10px;
		display: flex;
		justify-content: space-between;

		span {
			&:first-child {
				font-weight: bold;
			}
		}
	}
}

.table-condensed {
	tr {
		td {
			border: none;
		}
	}
}

tr.error {
	td {
		background-color: lightcoral !important;
	}
}

th.pr_input_qty {
	width: 6%;
}

div.pr_line1 {
	background-color: #f9f9f9;
}

div.pr_line0 {
	background-color: #ffffff;
}

.normal-ecart {
	color: red;
}

.homegeneite-moyenne {
	color: red;
}

.normal-ecart-tolere {
	color: red;
}

.normal-conforme {
	color: red;
}

.compa-ecart {
	color: red;
}

.compa-moyenne {
	color: red;
}

.compa-ecart-tolere {
	color: red;
}

.compa-conforme {
	color: red;
}

@media print {
	.no-print {
		display: none !important;

		* {
			display: none !important;
		}
	}

	@page {
		size: auto;
		margin: 0mm;
	}
}


.custom-section-fi {
	padding-left: 15px;

	input[type="radio"] {
		margin-left: 0;
	}
}

#burger-opener {
	color: white;
	font-size: 30px;
	margin-right: 30px;

	@media (min-width: $bp-tablet) {
		display: none;
	}
}

#user-action-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;

	@media (max-width: $bp-tablet) {
		display: none;
	}

	#stop-imperso {
		width: fit-content;
		height: fit-content;
		display: flex;
		justify-content: space-around;
		align-items: center;
		border-radius: 20px;
		border: 3px solid white;
		margin: 0 50px 0 0;
		color: white;

		a {
			padding: 10px;
			color: lightgray;

		}

		a:hover {
			color: white;
		}
	}
}

#user-action-menu-burger {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	#user-profile-div-burger {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: white;
	}

	#stop-imperso {
		width: fit-content;
		height: fit-content;
		display: flex;
		justify-content: space-around;
		align-items: center;
		border-radius: 20px;
		border: 3px solid white;
		margin: 0 50px 0 0;
		color: white;

		a {
			padding: 10px;
			color: lightgray;

		}

		a:hover {
			color: white;
		}
	}
}

// global
.form-v {
	display: flex;
	flex-direction: column;
	align-items: center;

	.radio {
		margin-bottom: 0;
	}
}

// global
.form-h {
	display: flex;
	flex-direction: row;
	align-items: center;
}

// global
.content-space-around {
	justify-content: space-around;
}

// global
.content-space-between {
	justify-content: space-between;
}

// global
.content-start {
	justify-content: flex-start;
}

.content-end {
	justify-content: flex-end;
}

// global
.spaced-input {
	margin: 10px 0;
}

// templates : Lot/formReception.html.twig, Lot/sechage.html.twig
#reception,
#sechage {
	.collection-item {
		display: flex;
		flex-direction: row;
		align-items: end;
		margin: 15px 0 0 0;

		input {
			margin: 0 10px;
		}
	}

	.unites-sechage {
		margin-right: 50px;

		.form-v {
			margin: 0 15px;
		}
	}

	.mesure-humidite-div,
	.unite-sechoir-div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: end;
	}
}

// templates : Lot/formReception.html.twig
#reception {
	.section-form {
		padding: 20px 20px;
		margin-bottom: 25px;

		input,
		select {
			background-color: #F0F0F0;
			border-radius: 0;
			border: 1px solid #DFDFDF;
		}

		label {
			font-weight: 700;
		}

		.custom-section-fi,
		.radio-inline {
			padding: 0;
		}
	}

	#reception-1 {
		border-bottom: 1px dashed lightgrey;

		.mesures-bennes-pleines{
			display: flex;
			flex-direction: column;
		}
	}

	#reception-2 {
		border: 1px dashed lightgrey;
		border-left: none;
		border-right: none;

		.mesures-humidite, .mesures-bennes-vides{
			display: flex;
			flex-direction: column;
		}
	}

	#reception-3 {
		border-top: 1px dashed lightgrey;
	}

	#sechage-div .form-h, #suivi-stockage-tampon .form-h {
		align-items: flex-end;
	}
}

// templates : sechoirplanning/suivi.html.twig
#sechoirs .sechoirs-section {
	border: 20px solid #3E5823;
	background-color: #3E5823;
	margin: 50px 0;
	border-radius: 50px;
	width: 100%;

	.section-title {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 0 10px;
		align-items: center;
		margin: 0;
		background-color: #3E5823;
		border-radius: 50px;

		h2 {
			color: white;
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
			margin: 0;
		}

		a {
			justify-self: right;
			color: white;
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;

			&:hover {
				color: #B8BE14;
			}
		}
	}
}

.mes-lots-poids{
	padding: 0;
	// border: none;

	table{
		width: 100%;
		td{
			padding: 8px;
			// width: 100%;
			border: none;
			text-align: left;
		}
	}
}


#resultats-recherche-stockage, #suivi-consommables-table{
	width: 100%;

	tr{
		width: 100%;

		&:nth-child(2n+1){
			background-color: #f2f2f2;
		}

		&:first-child{
			background-color: #3E5823;
			color: white;

			td{
				font-size: 15px;
			}
		}
	}

	td{
		padding: 2px 1px;
		text-align: center;
	}

	@media (max-width: $bp-tablet) {
		margin: 50px 0;
	}
}

@media (max-width: $bp-tablet) {

	#suivi-consommables-table, #suivi-consommables-table thead, #suivi-consommables-table tbody, #suivi-consommables-table th, #suivi-consommables-table td, #suivi-consommables-table tr { 
		display: block; 
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	#suivi-consommables-table tr:first-child, #suivi-consommables-table tr:nth-child(2) { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	#suivi-consommables-table tr { border: 1px solid #ccc; }

	#suivi-consommables-table td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}

	#suivi-consommables-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 0px;
		left: 6px;
		width: 45%; 
		padding: 3px 10px 3px 0; 
		white-space: nowrap;
		height: 100%;
		vertical-align: middle;
		background-color: #3E5823;
		color: white;
	}

	/*
	Label the data
	*/
	#suivi-consommables-table td:nth-of-type(1):before { content: "Type de consommables"; }
	#suivi-consommables-table td:nth-of-type(2):before { content: "En stock"; }
	#suivi-consommables-table td:nth-of-type(3):before { content: "Utilisés Saison N"; }
	#suivi-consommables-table td:nth-of-type(4):before { content: "Utilisés Saison N-1"; }
	#suivi-consommables-table td:nth-of-type(5):before { content: "Détruits Saison N"; }
	#suivi-consommables-table td:nth-of-type(6):before { content: "Détruits Saison N-1"; }
}
	

.stockage-form{
	border-radius: 40px;
	border: 4px solid #3E5823;
	padding: 20px 10px;
	margin: 20px 0;
	
	form{
		text-align: center;
	}

	form div{
		text-align: left;
	}

	label{
		margin: 10px 0 0 0;
	}

	ul li{
		color: red;
		font-weight: bold;
	}

	.stockage-form-title{
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		margin: 0 0 10px 0;
	}
	
	.ts-dropdown-content, .option{
		display: block;
		justify-content: flex-start;
		align-items: center;
	}

	.radio{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	input[type=checkbox]{
		width: 34px;
	}

	#recherche-chanvre, #recherche-autres, #quantite-manuelle-stockage, #quantite-manuelle-lot-cree{
		flex-direction: column;
		
		&>div{
			display: flex;
			flex-direction: column;
		}

		.ts-wrapper{
			min-height: 50px;
		}
	}

	#quantite-manuelle-lot-existant, #position-manuelle-lot{
		flex-direction: column;
	}

	.stockage-form-submit{
		padding: 10px 20px;
		border-radius: 30px;
		background-color: #3E5823;
		color: white;
		margin: 10px auto 0 auto;
	}
}

.stockage-link{
	margin: auto;
	width: fit-content;
	border-radius: 30px;
	padding: 10px 20px;
	background-color: #3E5823;
	color: white;
	margin: 20px 0;
	display: block;

	&:focus, &:hover{
		text-decoration: none;
		color: white;
	}
}

#historique-indispo-section td{
	padding: 10px 0;
}
  
.indispo-btn{
	padding: 10px 20px;
	margin: 0 10px;
	background-color: white;
	border: 1px solid black;
	border-radius: 30px;
}

.app-error{
	font-size: 25px;
}

.show-responsive{
	display: none;
}
  
// global - responsive
@media (max-width: $bp-tablet) {
	#hel_ccpscbundle_infosechagedepaillage_lot{
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		select{
			width: 100%;
		}
	}
	.show-responsive {
		display: block;
	}
	.table-to-card{
		margin: 20px 0;
		border-radius: 50px;
		border: 2px solid black;
		overflow: hidden;
	}
	.table-to-card.indispo-en-cours{
		background-color: lightgreen;
	}
	.table-to-card.indispo-en-attente{
		background-color: lightyellow;
	}
	.table-to-card.indispo-terminee{
		background-color: lightgrey;
	}
	.table-to-card .form-v{
		margin: 10px 0;
	}
	.table-to-card .form-h{
		width: 100%;
	}
	.table-to-card p{
		text-align: center;
		margin: 0;
	}
	.table-to-card .single{
		width: 100%;
	}
	.table-to-card .duo{
		width: 50%;
	}
	.table-to-card .trio{
		width: 33%;
	}
	.hide-responsive {
		display: none;
	}

	table {
		font-size: 12px;
	}
}